import React from 'react';
import "../styles/Footer.css";

export const Footer = () => {
    return (
        <footer className="flex bg-gray-600 text-gray-200 justify-center text-center">
            <p>©Copyright 2024 Setolo. All rights reserved.</p>
        </footer>
    )
}

export default Footer;