import React from "react";
import { useRoutes } from "react-router-dom"
import Footer from "./components/Footer.js"
import Navbar from "./components/Navbar.js"
import Sidebar from "./components/Sidebar.js"
import routes from "./config/routes.js";


const App = () => {
    const pageRoutes = useRoutes(routes);
    return (
        <div className="wrapper">
            <div className="content">
                <Sidebar />
                <div className="main-container overflow-hidden">
                    <Navbar />
                    <div className="ml-[60px] mt-[60px] w-[calc(100%-60px)] md:w-[calc(100%-180px)] h-full p-1 md:ml-[180px] md:p-10 bg-gray-800/90 text-gray-200 md:block border-t border-t-gray-600">
                        {pageRoutes}
                    </div>
                </div>
            </div>
            <div className="wallpaper"></div>
            <Footer />
        </div>
    )
}

export default App