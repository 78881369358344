import React from 'react';
import { useEffect, useState } from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const techTypes = [
    {
        type: "Next.js",
        path: "/images/tech-stack/nextjs-logo.svg",
        ext: "svg"
    },
    {
        type: "React",
        path: "/images/tech-stack/react-logo.png",
        ext: "png"
    },
    {
        type: "Node.js",
        path: "/images/tech-stack/nodejs-logo.svg",
        ext: "svg"
    },
    {
        type: "JavaScript",
        path: "/images/tech-stack/javascript-logo.png",
        ext: "png"
    },
    {
        type: "HTML5",
        path: "/images/tech-stack/html-logo.png",
        ext: "png"
    },
    {
        type: "TailwindCSS",
        path: "/images/tech-stack/tailwind-logo.png",
        ext: "png"
    },
    {
        type: "MongoDB",
        path: "/images/tech-stack/mongodb-logo.png",
        ext: "png"
    },
    {
        type: "C#",
        path: "/images/tech-stack/csharp-logo.png",
        ext: "png"
    },
    {
        type: "Python",
        path: "/images/tech-stack/python-logo.png",
        ext: "png"
    },
    {
        type: "GraphQL",
        path: "/images/tech-stack/graphql-logo.png",
        ext: "png"
    },
    
]

export const Landing = () => {

    return (
        <main>
            <p className="border-y border-y-gray-600 text-2xl text-center p-2 bg-gray-900 bg-opacity-75 mb-4">Discover all things <span className="text-green-400">Setolo</span></p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="tech-stack">
                    <div className="bg-gray-700 p-2 justify-center bg-opacity-75 box-shadow-sm border border-gray-600">
                        <div className="text-2xl p-4 font-bold bg-gray-900 bg-opacity-50 text-center">
                            What I use!
                        </div>
                        <div className="grid grid-rows-2 grid-flow-col mt-4 p-10">
                            {techTypes.map((tech, index) => (
                                <Tippy key={index} content={tech.type} >
                                    <div className="max-w-[96px] h-24 p-1">
                                        <img className="w-full h-full object-contain" src={tech.path} />
                                    </div>
                                </Tippy>
                            ))}
                        </div>
                    </div>
                    <div className="mt-4 bg-gray-700 p-2 justify-center bg-opacity-75 box-shadow-sm border border-gray-600">
                        <div className="text-2xl p-4 font-bold bg-gray-900 bg-opacity-50 text-center">
                            Games I play
                        </div>
                        <div className="flex items-center gap-2 mt-4 p-4">
                            My favorite types of games are single-player platformers, metroidvanias, and story-based games.
                        </div>
                    </div>
                </div>
                <div className="bg-gray-800 justify-center p-2 bg-gray-400 bg-opacity-10 backdrop-blur box-shadow-sm border border-gray-600">
                    <div className="text-2xl p-4 font-bold bg-gray-900 bg-opacity-50 text-center">
                        About Me
                    </div>
                    <div className="text-lg w-full h-full p-4">
                        Hi I'm Setolo! I'm someone that loves life and is obsessed with self-improvement. I love anime, games, art and music. My favorite type of anime is isekai, and my favorite type of music is EDM!
                        
                        <br /><br />
                        
                        I work as a programmer, and I love it.
                        The first language I've ever used was Lua. I slowly shifted towards Python, then C# where I made applications and simple games. Now, I create websites. 
                        
                        <br /><br />
                        
                        When I make websites for myself, I like to keep things simple and clean. I like to construct my own templates and design everything by hand. It may take longer, but <i>IT'S WORTH IT</i>.<br /><br />Professionally, I've really enjoyed working on websites too. It's much more meticulous, and based on the objectives of the website it can really change the whole foundation of the process.
                         
                    </div>
                </div>
            </div>
        </main>
    )
}