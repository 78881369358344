import React from 'react';
import { UserIcon } from "@heroicons/react/24/outline";
import "../styles/Navbar.css";

export const Navbar = () => {
    return (
        <nav className="navbar fixed top-0 z-40 backdrop-blur-sm bg-opacity-90 font-bold w-full bg-gray-800 text-gray-300 flex shadow-md">
            <div className="navbar-inner">
                <ul className="navbar-list">
                    <li><a alt="Login" href="#login" className="navbar-item hover:bg-gray-700 transition"><UserIcon width={24} height={24} /></a></li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;