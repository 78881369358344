import React from 'react';

export const Contact = () => {

    return (
        <div>
            <h1>Contact</h1>
            <p className="text-gray-500 text-sm">Contact, and more information</p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <br />Catch me on Discord, Steam, or other platforms as Setolo!<br /><br /> 
                    ....This page probably needs more work. 
                </div>
            </div>
        </div>
    )
}