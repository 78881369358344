import React, { useEffect, useState } from 'react';

export const NotFound = () => {
    const [showGame, setShowGame] = useState(false);

    // useEffect(() => {
    //     const showHiddenGame = () => {
    //         setShowGame(true);
    //     }

    //     setTimeout(showHiddenGame, 2000)
    // }, [])

    return (
        <div>
            <p className="flex justify-center items-center p-4 text-2xl font-bold">
                404 : Page not found.
            </p>

            {} 
            {showGame && (
                <div className="text-center text-amber-400 text-xl">
                    <p>You know what, NO! I don't like it when I try to go somewhere and there's nothing!</p>
                    So you know what, I'm going to give you something to do. Here's a game you can play:
                </div>
                
            ) }
        </div>
    )
}