import React from 'react';
import { InformationCircleIcon, FilmIcon, RocketLaunchIcon, MusicalNoteIcon, ForwardIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import "../styles/Sidebar.css";

export const Sidebar = () => {
    return (
        <aside className="fixed top-0 z-50 font-bold sidebar bg-gray-800 text-gray-300 text-center border-r border-r-gray-600">
            <Link to="/">
                <div className="site-logo hidden md:block">
                    <img className="rounded" src="/images/site-logo.png" alt="Setolo Online" />
                </div>
                <div className="h-[60px] block md:hidden bg-zinc-950">
                    <img className="rounded max-h-full ml-auto mr-auto" src="/images/site-logo-mobile.png" alt="Setolo Online" />
                </div>
            </Link>
            <ul>
                <li>
                    <Link className="sidebar-item hover:bg-gray-700 p-3 py-4 md:p-4 transition" to="/anime">
                        <div className="flex px-2">
                            <FilmIcon width={24} height={24} />
                            <div className="px-2 hidden md:block">Anime</div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link className="sidebar-item hover:bg-gray-700 p-3 py-4 md:p-4 transition" to="/game-rush">
                        <div className="flex px-2">
                            <ForwardIcon width={22} height={22} />
                            <div className="px-2 hidden md:block">Game Rush</div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link className="sidebar-item hover:bg-gray-700 p-3 py-4 md:p-4 transition" to="/games">
                        <div className="flex px-2">
                            <RocketLaunchIcon width={24} height={24} />
                            <div className="px-2 hidden md:block">Games</div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link className="sidebar-item hover:bg-gray-700 p-3 py-4 md:p-4 transition" to="/music">
                        <div className="flex px-2">
                            <MusicalNoteIcon width={24} height={24} />
                            <div className="px-2 hidden md:block">Music</div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link className="sidebar-item hover:bg-gray-700 p-3 py-4 md:p-4 transition" to="/contact">
                        <div className="flex px-2">
                            <InformationCircleIcon width={24} height={24} />
                            <div className="px-2 hidden md:block">Contact</div>
                        </div>
                    </Link>
                </li>
            </ul>
        </aside>
    )
}

export default Sidebar;