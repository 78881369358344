import React from 'react';

export const Music = () => {
    return (
        <div>
            <h1>Music</h1>
            <div className="p-4 text-red-400">(With earblasting volumes when you click play! Please click with caution.)</div>
            <div className="grid grid-cols-3 gap-4">
                <iframe title="Spotify Playlist" style={ { borderRadius: "12px" } } src="https://open.spotify.com/embed/playlist/3q1876nucc1csdA2AJLBLt?utm_source=generator&theme=0&volume=20" width="100%" height="80" allowFullScreen={false} allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                <iframe title="Spotify Playlist" style={ { borderRadius: "12px" } } src="https://open.spotify.com/embed/playlist/5a8Voj6OKSDLhPezZx54E7?utm_source=generator&theme=0&volume=20" width="100%" height="80" allowFullScreen={false} allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>
        </div>
    )
}