import React from 'react';
import { useEffect, useState } from "react";

export const Pacman = () => {
    const settings = [];
    const window = document.createElement("canvas");
    console.log("page working");
    
    return (
        <div>
            <h1>Pacman</h1>
            <div className="bg-gray-900 p-4 w-[540px]">
                This game actually hasn't been made yet. Be sure to check back later.
            </div>
        </div>
    )
}

export default Pacman;