import React from 'react';
import { useEffect, useState } from "react";
import "../styles/Games.css";
import { Link } from 'react-router-dom';

export const Games = () => {
    const [games, setGames] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const getGames = async () => {
            const gameDataResponse = await fetch("/api/steam-games", { signal });
            const gameData = await gameDataResponse.json();

            if (gameDataResponse) {
                setGames(gameData);
            }
        }

        getGames();
        
        return () => {
            if (controller) controller.abort();
        }
    }, [])
    
    return (
        <div className="select-none">
            <h1>Games</h1>
            <p className="text-gray-500 text-sm">Check out games I've made, or view games I play.</p>
            <div className="ml-auto mr-auto max-w-[460px] xl:max-w-[940px] 2xl:max-w-[1420px]">
                <div>
                    <div className="flex gap-1">
                        <Link to="/games/pacman" className="w-24 h-24 dev-games bg-gray-500 bg-opacity-40 hover:scale-105 transition-transform">
                            <img className="w-full h-full object-contain p-4" src="/images/big-pacman-icon.png" alt="pacman" />
                        </Link>
                        <Link to="/games/tetris" className="w-24 h-24 dev-games bg-gray-500 bg-opacity-40 hover:scale-105 transition-transform">
                            <img className="w-full h-full object-contain p-2" src="/images/tetris-logo.png" alt="pacman" />
                        </Link>
                    </div>
                    <div className="games-played flex flex-wrap py-2 gap-4 select-none">
                        {games.map((game, index) => (
                            <div key={index} className="box-shadow-sm border border-gray-800 hover:scale-105 transition-transform duration-300">
                                <img alt={game.name} className="pointer-events-none" src={`${game.header_image}`} />
                            </div>   
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}