import { Pacman } from "../pages/Pacman.js";
import { Contact } from "../pages/Contact.js";
import { Anime } from "../pages/Anime.js";
import { Games } from "../pages/Games.js";
import { Landing } from "../pages/Landing.js";
import { NotFound } from "../pages/404.js";
import { Music } from "../pages/Music.js";
import { GameRush } from "../pages/GameRush.js";

export const routes = [
    { path: "/",element: <Landing /> },
    { path: "anime", element: <Anime /> },
    { path: "contact", element: <Contact /> },
    { path: "music", element: <Music /> },
    { path: "games", element: <Games /> },
    { path: "game-rush", element: <GameRush /> },
    { path: "games/pacman", element: <Pacman /> },
    { path: "*", element: <NotFound /> }
]

export default routes;