import React from 'react';
import { useEffect, useState } from "react";
import "../styles/Anime.css";
import { ArrowPathIcon } from "@heroicons/react/24/outline"
import Tippy from '@tippyjs/react';

export const Anime = () => {
    const [animeList, setAnimeList] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const setAnimeData = (data) => {
            try {
                const list = data.MediaListCollection.lists[0];
                const entries = list.entries;
                if (entries.length > 0) {
                    setAnimeList(entries);
                }
            } catch (err) {
                console.error("An unknown error has occurred.");
            }
        }
        const getAnimeData = async () => {
            const dataResponse = await fetch("/api/anime-list", { signal });
            const data = await dataResponse.json();
            if (data) {
                setAnimeData(data);
            }
        }

        getAnimeData();
        
        return () => {
            if (controller) controller.abort();
        }
    }, []);

    return (
        <div className="relative h-full">
            <h1>Anime</h1>
            <p className="text-gray-500 text-sm">Check out anime Setolo's watching</p>
            <div className="current text-2xl font-semibold mt-4 text-center">
                <span className="border-b-4 border-b-blue-500">Currently Watching</span>
            </div>
            <div className="flex gap-2 flex-wrap p-4 relative justify-center">
                {animeList.length === 0 && [...Array(7)].map((el, index) => (
                    <div key={index}>
                        <div className="w-[220px]">
                            <div className="h-[320px] rounded-md overflow-hidden box-shadow-sm relative">
                                <div className="absolute bottom-2 right-2 bg-gray-900 rounded-md p-1 bg-opacity-90">
                                    Episode: 
                                </div>
                                <div className="absolute top-2 left-2 bg-gray-900 rounded-md p-1 bg-opacity-90">
                                    Score: 
                                </div>
                            </div>
                            <div className="anime-name w-full p-2 text-center">
                                
                            </div>
                        </div>
                    </div>
                ))}
                {animeList.map(entry => (
                    <div key={entry.media.id}>
                        <div className="w-[220px]">
                            <div className="h-[320px] rounded-md overflow-hidden box-shadow-sm relative">
                                <img alt={entry.media.title.english} className="w-full h-full object-cover" src={entry.media.coverImage.large} />
                                <div className="absolute bottom-2 right-2 bg-gray-900 rounded-md p-1 bg-opacity-90">
                                    Episode: {entry.progress} / {entry.media.episodes || "?"}
                                </div>
                                <div className="absolute top-2 left-2 bg-gray-900 rounded-md p-1 bg-opacity-90">
                                    Score: {entry.score}
                                </div>
                                {entry.repeat > 0 && <div className="absolute top-2 right-2 bg-gray-900 rounded-md p-1 bg-opacity-90">
                                    <Tippy content={`Re-watched ${entry.repeat} times`}>
                                        <ArrowPathIcon className="w-6 h-6" />
                                    </Tippy>
                                </div>}
                            </div>
                            <div className="anime-name w-full p-2 text-center">
                                {entry.media.title.english}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="text-xs font-bold text-gray-400 text-center absolute bottom-1 w-full">
                Check out my full list on <a rel="noreferrer" target="_blank" className="text-blue-400 hover:text-blue-600" href="https://anilist.co/user/Setolo/animelist">AniList</a>
            </div>
        </div>
    )
}